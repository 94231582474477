import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth, googleProvider } from "../config/firebase";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [isVerified, setIsVerified] = useState(false); // <--- New State for email verification status
  const [registrationMessage, setRegistrationMessage] = useState("");

  // Create new user with FORM
  const createUser = async (email, password) => {
    const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
    );
    await sendEmailVerification(userCredential.user);

    // Log out user after sending verification
    await signOut(auth);

    // Set registration message
    setRegistrationMessage(
        "Thank you for registering! Please verify your email before logging in."
    );

    return userCredential;
  };

  // SignUp with google function
  const signInWithGoogle = () => {
    return signInWithPopup(auth, googleProvider);
  };

  // Login using FORM
  const signIn = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
    );
    if (!userCredential.user.emailVerified) {
      await signOut(auth); // Ensure the user is logged out if email is not verified
      throw new Error("Please verify your email before logging in.");
    }
    return userCredential;
  };

  // Logout
  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setIsVerified(currentUser?.emailVerified || false); // <--- Set the verification status here
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
      <UserContext.Provider
          value={{
            createUser,
            user,
            isVerified,
            logout,
            signIn,
            signInWithGoogle,
            registrationMessage,
          }}
      >
        {children}
      </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
