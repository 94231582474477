import React from "react";
import { Link } from "react-router-dom";

const Premium = () => {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-purple-800 via-purple-600 to-indigo-800 text-white">
            <div className="p-8 rounded-lg shadow-lg max-w-md text-center">
                <h1 className="text-4xl font-bold mb-6 text-blue-300 animate-fade-in">
                    Selamat datang di AsikTravel!
                </h1>
                <p className="text-lg mb-8 text-white leading-relaxed animate-fade-in">
                    Bersiaplah untuk petualangan tak terlupakan! AsikTravel adalah aplikasi rekomendasi dan perencanaan perjalanan yang menggunakan teknologi AI terdepan. Kami akan mempertimbangkan selera, gaya, dan preferensi Anda dengan cermat, dan merekomendasikan destinasi impian Anda - tempat-tempat menakjubkan yang belum pernah Anda temui sebelumnya.
                </p>
                <p className="text-lg text-white leading-relaxed animate-fade-in">
                    Jadilah yang pertama merasakan masa depan perjalanan. Daftar sekarang untuk mendapatkan akses eksklusif ke fitur revolusioner ini dan menjadi yang pertama tahu ketika fitur ini akan tersedia untuk pengguna yang terdaftar!
                </p>
                <Link to="/" className="mt-8 bg-purple-800 text-white py-3 px-6 rounded-lg font-medium hover:bg-black transition-all duration-300">
                    Go Back to Homepage
                </Link>
            </div>
        </div>
    );
};

export default Premium;
