import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDIRbVS6L4pcf-GoAZEimUGK_itAg-H3BY",
  authDomain: "asiktravel-35e87.firebaseapp.com",
  projectId: "asiktravel-35e87",
  storageBucket: "asiktravel-35e87.appspot.com",
  messagingSenderId: "350607000705",
  appId: "1:350607000705:web:9af136ad5cf2f3fff0b980",
  measurementId: "G-2TP10NV5KF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// instantiate auth for basic login and signup form
export const auth = getAuth(app);

// instantiate auth for signIn with Google
export const googleProvider = new GoogleAuthProvider();

// Adding Firestore db on frontend
export const db = getFirestore(app);
