import { FiSunrise, FiSunset, FiSun } from "react-icons/fi";
import { useState, useEffect } from "react";

export default function CardItinerary(props) {
  const [morningImage, setMorningImage] = useState("");
  const [afternoonImage, setAfternoonImage] = useState("");
  const [eveningImage, setEveningImage] = useState("");
  const [currentDate, setCurrentDate] = useState("");

  // placeholder image
  const placeholderImage =
    "https://res.cloudinary.com/djtigzyfc/image/upload/f_auto,q_auto/ltflp1ffqpfpvmbye6sl";

  const handleClick = () => {
    props.onItemClick(props.dayNum, morningImage, afternoonImage, eveningImage);
  };

  useEffect(() => {
    const fetchGooglePlacesImages = async () => {
      const fetchImage = async (name, setImage) => {
        // Check if the image is already stored in the localStorage
        const storedImage = localStorage.getItem(`${name}, ${props.city}`);

        if (storedImage) {
          setImage(storedImage);
        } else {
          try {
            const response = await fetch(
              `${
                process.env.REACT_APP_api_url
              }api/placephoto?placeName=${encodeURIComponent(
                name
              )}&city=${encodeURIComponent(props.city)}`
            );
            const data = await response.json();

            if (data.photoUrl) {
              setImage(data.photoUrl);
              // Store the image URL in localStorage
              localStorage.setItem(`${name}, ${props.city}`, data.photoUrl);
            } else {
              console.log(`No image found for ${name}`);
              setImage(placeholderImage);
            }
          } catch (error) {
            console.log(
              `Error fetching image from Google Places for ${name}:`,
              error
            );
          }
        }
      };

      fetchImage(`${props.item.morning.placeName}`, setMorningImage);

      fetchImage(`${props.item.afternoon.placeName}`, setAfternoonImage);

      fetchImage(`${props.item.evening.placeName}`, setEveningImage);
    };

    fetchGooglePlacesImages();
  }, []);

  useEffect(() => {
    props.onItemImagesChange(
      props.dayNum,
      morningImage,
      afternoonImage,
      eveningImage
    );
  }, [morningImage, afternoonImage, eveningImage]);

  useEffect(() => {
    const today = new Date();
    const futureDate = new Date();
    futureDate.setDate(today.getDate() + props.dayNum + 1); // Add 1 to skip the current day

    const options = { weekday: "long", month: "long", day: "numeric" };
    const formattedDate = futureDate.toLocaleDateString("en-US", options);
    setCurrentDate(formattedDate);
  }, [props.dayNum]);

  // To open viator booking link when 'Get Tickets' button is clicked
  const openLinkInNewTab = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <h2
        className={`${
          props.dayNum !== 0 ? "mt-8" : "mt-0"
        } mb-4 block font-bold text-2xl text-gray-200`}
        id={`day${props.dayNum + 1}`}
      >
        Day {props.dayNum + 1}{" "}
        <p className="text-sm text-gray-500 font-sans">{currentDate}</p>
      </h2>
      <ul
        className={`relative bg-white text-[#152535] rounded-lg py-10 pl-2 pr-1 col-span-3 ${
          props.isSelected ? "selected" : ""
        }`}
        onClick={handleClick}
      >
        <li>
          <FiSunrise className="absolute left-2" title="Morning" size={20} />
          <h3 className="ml-10 mb-2">{props.item.morning.title}</h3>
          <p className="ml-10 mt-2 mb-4 font-signika">
            {props.item.morning.activity}
          </p>
          <div className="w-4/5  mx-auto">
            <div className="image-container">
              <img
                className="rounded-xl pop-out transform cursor-pointer"
                src={morningImage}
                alt="Morning Image"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = placeholderImage;
                }}
              />

              <button
                className="book-now-button"
                onClick={() =>
                  openLinkInNewTab(
                    `https://www.viator.com/searchResults/all?pid=P00103625&mcid=42383&medium=link&text=${props.item.morning.placeName} ${props.city}`
                  )
                }
              >
                Pesan Sekarang
              </button>
            </div>
          </div>
        </li>
        <li>
          <FiSun className="absolute left-2" title="Afternoon" size={20} />
          <h3 className="ml-10 mt-8 mb-2">{props.item.afternoon.title}</h3>
          <p className="ml-10 mt-2 mb-4 font-signika">
            {props.item.afternoon.activity}
          </p>
          <div className="w-4/5 mx-auto">
            <div className="image-container">
              <img
                className="rounded-xl pop-out transform cursor-pointer"
                src={afternoonImage}
                alt="Afternoon Image"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = placeholderImage;
                }}
              />
              <button
                className="book-now-button"
                onClick={() =>
                  openLinkInNewTab(
                    `https://www.viator.com/searchResults/all?pid=P00103625&mcid=42383&medium=link&text=${props.item.afternoon.placeName} ${props.city}`
                  )
                }
              >
                Pesan Sekarang
              </button>
            </div>
          </div>
        </li>
        <li>
          <FiSunset className="absolute left-2" title="Evening" size={20} />
          <h3 className="ml-10 mt-8 mb-2">{props.item.evening.title}</h3>
          <p className="ml-10 mt-2 mb-4 font-signika">
            {props.item.evening.activity}
          </p>
          <div className="w-4/5 mx-auto">
            <div className="image-container">
              <img
                className="rounded-xl pop-out transform cursor-pointer"
                src={eveningImage}
                alt="Evening Image"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = placeholderImage;
                }}
              />
              <button
                className="book-now-button"
                onClick={() =>
                  openLinkInNewTab(
                    `https://www.viator.com/searchResults/all?pid=P00103625&mcid=42383&medium=link&text=${props.item.evening.placeName} ${props.city}`
                  )
                }
              >
                Pesan Sekarang
              </button>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
}
