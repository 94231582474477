/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { css, keyframes } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading } from "react-icons/ai";
import { IconButton } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"; // Mui icon
import TravelAnimation from "../components/TravelAnimation";

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const Test = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);

  const [showMore, setShowMore] = useState(false); // New state to handle showing more form fields

  const loadingMessages = [
    "Creating your dream adventure",
    "Preparing the maps and compass",
    "Gathering recommendations from local experts",
    "Packing up excitement and wanderlust",
  ];

  useEffect(() => {
    let timer;
    if (loading && messageIndex < loadingMessages.length - 1) {
      timer = setTimeout(() => {
        setMessageIndex(messageIndex + 1);
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [loading, messageIndex]);

  const [formData, setFormData] = useState({
    travellerType: "",
    travelMonth: "",
    cityName: "",
    distanceFromLocation: 200,
    tripDuration: 3,
    specialOccasion: "",
    budget: "",
    travellingAs: "solo traveler",
    travelStyle: "",
    terrain: "",
    weatherConditions: "",
    language: "",
  });

  const handleGoHome = () => {
    navigate("/");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate("/premium");
    }, 5000);
  };

  if (loading) {
    return (
      <div
        className="flex flex-col items-center justify-center h-screen text-center bg-white text-black"
        css={css`
          animation: ${slideIn} 1s ease-out forwards;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            max-height: calc(100vh - 200px);
            overflow: auto;
            padding: 0 20px; /* Add this line */
            text-align: center; /* Add this line */
          `}
        >
          <AiOutlineLoading
            css={css`
              animation: spin 1s linear infinite;
              margin-bottom: 10px;
            `}
          />
          <h2
            className="text-2xl mt-4"
            css={css`
              font-family: "Montserrat", sans-serif;
              font-weight: bold;
              color: #2b6cb0;
              text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
              margin: 0;
              max-width: 100%; /* Add this line */
              padding: 0 20px; /* Add this line */
            `}
          >
            {loadingMessages[messageIndex]}
          </h2>
          <TravelAnimation />
        </div>
      </div>
    );
  }

  return (
    <div
      className="min-h-screen flex pt-40 pb-40 justify-center px-4"
      css={css`
        animation: ${slideIn} 1s ease-out forwards;
        background: black;
        background-image: url("https://res.cloudinary.com/djtigzyfc/image/upload/f_auto,q_auto/v1/aitravel/zipwvyx09tzknez6lqrg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      `}
    >
      <div
        className="rounded-lg shadow-lg p-8 w-full sm:w-auto"
        css={css`
          background: rgba(255, 255, 255, 0.15);
          backdrop-filter: blur(5px);
        `}
      >
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-3xl text-white font-bold leading-tight mt-0 mb-0">
            Unlock Your Journey
          </h1>
          <IconButton
            onClick={handleGoHome}
            className="hover:bg-blue-500 transition duration-300 shadow-lg"
          >
            <HomeIcon
              className="text-purple-400 hover:text-purple-600"
              style={{ fontSize: 35 }}
              title="Go back to the homepage"
            />
          </IconButton>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="travellerType"
                className="text-lg mb-1 block text-gray-200"
              >
                Who are you ?*
              </label>
              <select
                id="travellerType"
                name="travellerType"
                value={formData.travellerType}
                onChange={handleInputChange}
                required
                className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
              >
                <option value="">Select an option</option>
                <option value="Explorer">Explorer</option>
                <option value="Tourist">Tourist</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="travelMonth"
                className="text-lg mb-1 block text-gray-200"
              >
                When ?*
              </label>
              <select
                id="travelMonth"
                name="travelMonth"
                value={formData.travelMonth}
                onChange={handleInputChange}
                required
                className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
              >
                <option value="">Select a month</option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="cityName"
                className="text-lg mb-1 block text-gray-200"
              >
                Current location*
              </label>
              <input
                type="text"
                id="cityName"
                name="cityName"
                placeholder="Enter your city name"
                value={formData.cityName}
                onChange={handleInputChange}
                required
                className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300"
              />
            </div>

            <div>
              <label
                htmlFor="distanceFromLocation"
                className="text-lg mb-1 block text-gray-200"
              >
                Distance from your location
              </label>
              <input
                type="range"
                id="distanceFromLocation"
                name="distanceFromLocation"
                min={50}
                max={15000}
                value={formData.distanceFromLocation || 500}
                onChange={handleInputChange}
                className="w-full focus:outline-none  focus:ring-blue-500 transition-colors duration-300 cursor-pointer"
                style={{
                  background: "linear-gradient(to right, #2b6cb0, #4299e1)",
                  color: "black",
                }}
              />
              <span className="text-gray-300">
                {formData.distanceFromLocation || 500} KM
              </span>
            </div>
            <div>
              <label
                htmlFor="tripDuration"
                className="text-lg mb-1 block text-gray-200"
              >
                Trip Duration (days)
              </label>
              <input
                type="range"
                id="tripDuration"
                name="tripDuration"
                min={1}
                max={5}
                value={formData.tripDuration}
                onChange={handleInputChange}
                className="w-full focus:outline-none  focus:ring-blue-500 transition-colors duration-300 cursor-pointer"
                style={{
                  background: "linear-gradient(to right, #2b6cb0, #4299e1)",
                  color: "black",
                }}
              />
              <span className="text-gray-300">{formData.tripDuration}</span>
            </div>
            <div>
              <label
                htmlFor="specialOccasion"
                className="text-lg mb-1 block text-gray-200"
              >
                Special Occasion
              </label>
              <select
                id="specialOccasion"
                name="specialOccasion"
                value={formData.specialOccasion}
                onChange={handleInputChange}
                className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
              >
                <option value="">Select an occasion</option>
                <option value="Honeymoon">Honeymoon</option>
                <option value="Anniversary">Anniversary</option>
                <option value="Birthday party">Birthday party</option>
                <option value="Graduation celebration">
                  Graduation celebration
                </option>
                <option value="Bachelor party">Bachelor party</option>
                <option value="Bachelorette party">Bachelorette party</option>
                <option value="Family reunion">Family reunion</option>
                <option value="Retirement getaway">Retirement getaway</option>
              </select>
            </div>

            <div className="col-span-2 text-center">
              <IconButton onClick={() => setShowMore(!showMore)}>
                <AddCircleOutlineIcon
                  className="text-white animate-pulse"
                  style={{ fontSize: 40 }}
                />
              </IconButton>
            </div>

            {/* Remaining inputs, hidden initially */}
            {showMore && (
              <>
                <div>
                  <label
                    htmlFor="language"
                    className="text-lg mb-1 block text-gray-200"
                  >
                    Change Language?
                  </label>
                  <select
                    id="language"
                    name="language"
                    value={formData.language}
                    onChange={handleInputChange}
                    required
                    className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
                  >
                    <option value="">Select a language</option>
                    <option value="english">English</option>
                    <option value="spanish">Spanish</option>
                    <option value="french">French</option>
                    <option value="german">German</option>
                    <option value="chinese">Chinese</option>
                    <option value="japanese">Japanese</option>
                    <option value="portuguese">Portuguese</option>
                    <option value="russian">Russian</option>
                    <option value="arabic">Arabic</option>
                    <option value="italian">Italian</option>
                    <option value="korean">Korean</option>
                    <option value="dutch">Dutch</option>
                    <option value="swedish">Swedish</option>
                    <option value="danish">Danish</option>
                    <option value="norwegian">Norwegian</option>
                    <option value="finnish">Finnish</option>
                    <option value="polish">Polish</option>
                    <option value="turkish">Turkish</option>
                    <option value="greek">Greek</option>
                    <option value="hindi">Hindi</option>
                    <option value="indonesian">Indonesian</option>
                    <option value="malay">Malay</option>
                    <option value="vietnamese">Vietnamese</option>
                    <option value="thai">Thai</option>
                    <option value="hebrew">Hebrew</option>
                    <option value="czech">Czech</option>
                    <option value="hungarian">Hungarian</option>
                    <option value="romanian">Romanian</option>
                    <option value="bulgarian">Bulgarian</option>
                    <option value="ukrainian">Ukrainian</option>
                  </select>
                </div>

                <div>
                  <label
                    htmlFor="budget"
                    className="text-lg mb-1 block text-gray-200"
                  >
                    Budget
                  </label>
                  <input
                    type="text"
                    id="budget"
                    name="budget"
                    placeholder="in USD (excl. airfare)"
                    value={formData.budget}
                    onChange={handleInputChange}
                    className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300"
                  />
                </div>
                <div>
                  <label
                    htmlFor="travellingAs"
                    className="text-lg mb-1 block text-gray-200"
                  >
                    Travelling as
                  </label>
                  <select
                    id="travellingAs"
                    name="travellingAs"
                    value={formData.travellingAs}
                    onChange={handleInputChange}
                    className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
                  >
                    <option value="solo traveler">Solo Traveler</option>
                    <option value="Family">Family</option>
                    <option value="Couple">Couple</option>
                    <option value="Friends">Friends</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="travelStyle"
                    className="text-lg mb-1 block text-gray-200"
                  >
                    Travel Style
                  </label>
                  <select
                    id="travelStyle"
                    name="travelStyle"
                    value={formData.travelStyle}
                    onChange={handleInputChange}
                    className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
                  >
                    <option value="">Select an option</option>
                    <option value="Road trip">Road trip</option>
                    <option value="Cultural">Cultural</option>
                    <option value="SightSeeing">SightSeeing</option>
                    <option value="Museums">Museums</option>
                    <option value="Adventure">Adventure</option>
                    <option value="Relaxation">Relaxation</option>
                    <option value="Wildlife exploration">
                      Wildlife exploration
                    </option>
                    <option value="Camping/Hiking">Camping/Hiking</option>
                    <option value="Theme parks">Theme parks</option>
                    <option value="Nightlife">Nightlife</option>
                    <option value="Outdoor">Outdoor</option>
                  </select>
                </div>

                <div>
                  <label
                    htmlFor="terrain"
                    className="text-lg mb-1 block text-gray-200"
                  >
                    Terrain
                  </label>
                  <select
                    id="terrain"
                    name="terrain"
                    value={formData.terrain}
                    onChange={handleInputChange}
                    className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
                  >
                    <option value="">Select terrain</option>
                    <option value="Mountain">Mountain</option>
                    <option value="Beach">Beach</option>
                    <option value="Plain">Plain</option>
                    <option value="Forest">Forest</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="weatherConditions"
                    className="text-lg mb-1 block text-gray-200"
                  >
                    Weather conditions
                  </label>
                  <select
                    id="weatherConditions"
                    name="weatherConditions"
                    value={formData.weatherConditions}
                    onChange={handleInputChange}
                    className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
                  >
                    <option value="">Select weather</option>
                    <option value="Sunny">Sunny</option>
                    <option value="Cold">Cold</option>
                    <option value="Rain">Rain</option>
                    <option value="Snowfall">Snowfall</option>
                  </select>
                </div>
              </>
            )}
          </div>
          <button
            type="submit"
            className="bg-[#7b3af1] text-white rounded-lg py-2 px-4 w-full sm:w-auto hover:bg-blue-600 transition-all duration-300 cursor-pointer"
          >
            Find My Destination
          </button>
        </form>
      </div>
    </div>
  );
};

export default Test;
