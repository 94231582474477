const cat = [
  "Sorry for the delay, my cat thought deadlines were for chasing",
  "Apologies for the delay, my cat's on the job and it's a real cat-astrophe.",
  "Sorry for the delay, my cat thought deadlines were for chasing.",
  "Apologies for the delay, my cat decided to take a cat nap on my keyboard.",
  "Forgive the delay, my cat insisted on inspecting every word I typed.",
  "Sorry its taking longer, my cat demanded an emergency cuddle session.",
  "Apologies for the delay, my cat sabotaged the 'send' button.",
  "Pardon the wait, my cat decided to play hide-and-seek with my important documents.",
  "Sorry for the delay, my cat decided to conduct a 'purrformance review' of my work.",
  "Apologies for the delay, my cat got hold of my mouse and went on a click frenzy.",
  "Forgive the tardiness, my cat deemed it necessary to supervise every keystroke.",
  "Sorry its taking a while, my cat insisted on proofreading everything with a 'meow-ditorial' touch.",
  "Sorry, cat delay!",
  "Cat took over, apologies!",
  "Meow-sorry for the wait!",
  "Blame the cat for the delay!",
  "Delayed by feline antics, sorry!",
  "Cat-astrophic delay, my bad!",
  "Apologies, cat chaos causing delay!",
  "Cat-tastic delay, forgive me!",
  "Oops! Cat-related delay, sorry!",
  "Delayed by a purr-fectly mischievous cat!",
];

export default cat;
