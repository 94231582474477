import React from "react";

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white p-4">
      <div className="text-center space-y-4 max-w-lg">
        <p className="text-2xl md:text-3xl font-bold">Waduh! Salah Kosmik!</p>
        <p className="text-lg md:text-xl">
          Halo, Sobat! Halaman yang kamu cari ilang ditelan lubang hitam. Gak
          ada nih di sini! 🛸
        </p>
        <p className="text-sm md:text-base mt-2">
          Pesannya buat Alien: [Brzzt! Wkwk. Kosong, Gan! 🪐]
        </p>
        <a
          href="/"
          className="mt-6 inline-block px-4 py-2 md:px-8 md:py-3 bg-purple-500 rounded-full text-white hover:bg-green-600 hover:text-white transition duration-300"
        >
          Balik ke markas
        </a>
      </div>
    </div>
  );
};

export default NotFound;
