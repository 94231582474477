import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import RoamReady from "./pages/RoamReady";
import Itinerary from "./pages/Itinerary";
import Test from "./pages/Test";
import Premium from "./pages/Premium";
import Account from "./pages/Account";
import { AuthContextProvider } from "./context/AuthContext";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import AboutUs from "./pages/AboutUs";
import NotFound from "./pages/NotFound";
import Suggestions from "./pages/Suggestions";
import SuggestionsForm from "./pages/SuggestionsForm";
import Labuan from './pages/Labuan';
import Phongnha from "./pages/Phongnha";

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<RoamReady />} />
            <Route path="/itinerary/:id/:ignore" element={<Itinerary />} />
            <Route path="/custom/" element={<SuggestionsForm/>} />
            <Route path="/premium" element={<Premium />} />

            <Route path="/suggestions/:id/:ignore" element={<Suggestions />} />
            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              }
            />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/labuan-bajo-tour" element={<Labuan />} />
            <Route path="/vietnam-tour" element={<Phongnha />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </AuthContextProvider>
    </div>
  );
}

export default App;
