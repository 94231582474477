/** @jsxImportSource @emotion/react */
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { css, keyframes } from "@emotion/react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLoading } from "react-icons/ai";
import { IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"; // Mui icon
import TravelAnimation from "../components/TravelAnimation";
import { v4 as uuidv4 } from "uuid";
import { FaHome } from "react-icons/fa";
import { UserAuth } from "../context/AuthContext";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const slideIn = keyframes`
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  `;

const SuggestionsForm = () => {
    const { user } = UserAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [messageIndex, setMessageIndex] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [showMore, setShowMore] = useState(false); // New state to handle showing more form fields
    const [cityOptions, setCityOptions] = useState([]);
    const [isCityDropdownOpen, setIsCityDropdownOpen] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const loadingMessages = [
        "Creating your dream adventure",
        "Preparing the maps and compass",
        "Gathering recommendations from local experts",
        "Packing up excitement and wanderlust",
    ];

    useEffect(() => {
        let timer;
        if (loading && messageIndex < loadingMessages.length - 1) {
            timer = setTimeout(() => {
                setMessageIndex(messageIndex + 1);
            }, 2500);
        }
        return () => clearTimeout(timer);
    }, [loading, messageIndex]);

    const [formData, setFormData] = useState({
        travellerType: "",
        travelMonth: "",
        cityName: "",
        distanceFromLocation: 200,
        tripDuration: 3,
        specialOccasion: "",
        budget: "",
        travellingAs: "solo traveler",
        travelStyle: "",
        terrain: "",
        weatherConditions: "",
    });

    const cityNameInputRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                cityNameInputRef.current &&
                !cityNameInputRef.current.contains(event.target)
            ) {
                setIsCityDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    // Function to toggle the popup visibility
    const togglePopup = () => {
        setShowPopup((prevShowPopup) => !prevShowPopup);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the user is logged in
        if (!user) {
            // Show the popup and return, preventing form submission
            togglePopup();
            return;
        }

        // Proceed with form submission if the user is logged in
        setLoading(true);

        try {
            const uniqueId = uuidv4();
            // Make a POST request to the backend with the form data
            const response = await axios.post(
                `${process.env.REACT_APP_api_url}api/recommendations`,
                formData
            );

            // Check if the response contains the completed itinerary
            if (response && response.data && response.data.suggestions) {
                const { suggestions } = response.data;

                // Navigate to the /itinerary page with the completed itinerary as state
                navigate(`/suggestions/${formData.cityName}/${uniqueId}`, {
                    state: { jsonData: suggestions, formData: formData },
                });
            } else {
                console.error("Failed to get completed itinerary");
            }
        } catch (error) {
            console.error("Error while fetching itinerary from backend:", error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div
                className="flex flex-col items-center justify-center h-screen text-center bg-white text-black"
                css={css`
          animation: ${slideIn} 1s ease-out forwards;
        `}
            >
                <div
                    css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            max-height: calc(100vh - 200px);
            overflow: auto;
            padding: 0 20px; /* Add this line */
            text-align: center; /* Add this line */
          `}
                >
                    <AiOutlineLoading
                        css={css`
              animation: spin 1s linear infinite;
              margin-bottom: 10px;
            `}
                    />
                    <h2
                        className="text-2xl mt-4"
                        css={css`
              font-family: "Montserrat", sans-serif;
              font-weight: bold;
              color: #2b6cb0;
              text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
              margin: 0;
              max-width: 100%; /* Add this line */
              padding: 0 20px; /* Add this line */
            `}
                    >
                        {loadingMessages[messageIndex]}
                    </h2>
                    <TravelAnimation />
                </div>
            </div>
        );
    }

    const popup = (
        <div
            className="fixed inset-0 flex items-center justify-center z-50"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
            <div className="bg-white p-8 rounded-lg max-w-md">
                <p className="text-lg font-bold mb-4">
                    Unlock premium features! Sign up or log in to embark on the adventure!
                </p>
                <div className="flex justify-center space-x-4">
                    <button
                        className="bg-blue-500 text-white rounded-lg py-2 px-4 hover:bg-blue-600 transition-all duration-300"
                        onClick={() => {
                            // Redirect to signup page when clicked
                            navigate("/signup");
                        }}
                    >
                        Go to signup page
                    </button>
                    <button
                        className="bg-gray-300 text-gray-700 rounded-lg py-2 px-4 hover:bg-gray-400 transition-all duration-300"
                        onClick={() => {
                            // Close the popup and navigate to the home page when clicked
                            togglePopup();
                            navigate("/");
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );

    const handleCityInputChange = (e, value, reason) => {

        // Always update the form data with the typed value
        setFormData((prevFormData) => ({
            ...prevFormData,
            cityName: value ? value.description || value : "", // Use value.description if available, else use an empty string
        }));

        if (reason === "input") {
            setIsCityDropdownOpen(true); // Open the dropdown

            if (value.length >= 1) {
                // Check if value length is greater than or equal to 1
                // Fetch autocomplete options based on the input value
                fetchCityOptions(value);
            } else {
                // If the input is empty, close the dropdown and clear the input value
                setIsCityDropdownOpen(false);
                setCityOptions([]);
            }
        }
    };

    const fetchCityOptions = async (inputValue) => {
        try {
            setIsFetching(true);
            // Make a request to fetch autocomplete options based on the input value
            // Replace `${process.env.REACT_APP_api_url}` with your API URL for fetching city suggestions.
            const response = await axios.get(
                `${process.env.REACT_APP_api_url}api/autocomplete?input=${inputValue}`
            );
            setCityOptions(response.data); // Use response.data instead of response.json()
        } catch (error) {
            console.error("City autocomplete fetch failed", error);
            setCityOptions([]);
        } finally {
            setIsFetching(false);
        }
    };
    return (
        <div
            className="min-h-screen flex pt-40 pb-40 justify-center px-4 "
            css={css`
        animation: ${slideIn} 1s ease-out forwards;
        background: black;
        background-image: url("https://res.cloudinary.com/ddw6tgza0/image/upload/f_auto,q_auto/v1/homepage%20videos/horizontal/asqsca1kcwi1anqo2mrn");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      `}
        >
            <div
                className="rounded-lg shadow-lg p-8 w-full sm:w-auto"
                css={css`
          background: rgba(255, 255, 255, 0.15);
          backdrop-filter: blur(5px);
        `}
            >
                <h1 className="text-xl text-white font-bold mb-4 text-center">
                    Temukan Petualangan Anda
                </h1>
                <div className="fixed top-4 right-4 bg-gray-800 text-white rounded-full p-3 shadow-lg transition duration-300 ease-in-out hover:bg-gray-700 focus:outline-none">
                    <Link
                        to="/"
                        className="text-white hover:text-gray-300 transition duration-200"
                    >
                        <FaHome className="h-6 w-6" />
                    </Link>
                </div>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label
                                htmlFor="travellerType"
                                className="text-lg mb-1 block text-gray-200"
                            >
                                Tipe Wisatawan ?*
                            </label>
                            <select
                                id="travellerType"
                                name="travellerType"
                                value={formData.travellerType}
                                onChange={handleInputChange}
                                required
                                className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
                            >
                                <option value="">Select an option</option>
                                <option value="Explorer">Pelopor Wisata</option>
                                <option value="Tourist">Turis</option>
                            </select>
                        </div>
                        <div>
                            <label
                                htmlFor="travelMonth"
                                className="text-lg mb-1 block text-gray-200"
                            >
                                Berangkat ?*
                            </label>
                            <select
                                id="travelMonth"
                                name="travelMonth"
                                value={formData.travelMonth}
                                onChange={handleInputChange}
                                required
                                className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
                            >
                                <option value="">Select a month</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                            </select>
                        </div>
                        <div className="col-span-2">
                            <label
                                htmlFor="cityName"
                                className="text-lg mb-1 block text-gray-200"
                            >
                                Lokasi saat ini*
                            </label>
                            <Autocomplete
                                id="cityName"
                                options={cityOptions}
                                getOptionLabel={(option) => option.description || ""}
                                noOptionsText={isFetching ? "Loading..." : "NO OPTIONS"}
                                style={{ width: "100%" }}
                                onInputChange={handleCityInputChange} // Only use the handleCityInputChange function
                                open={isCityDropdownOpen}
                                clearOnBlur={true}
                                disableCloseOnSelect={true}
                                freeSolo={false} // Disallow free typing
                                popupIcon={null}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputRef={cityNameInputRef}
                                        type="text"
                                        placeholder="Masukkan kota keberangkatan"
                                        className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300"
                                        required
                                    />
                                )}
                            />
                        </div>

                        <div>
                            <label
                                htmlFor="distanceFromLocation"
                                className="text-lg mb-1 block text-gray-200"
                            >
                                Jarak Pencarian
                            </label>
                            <input
                                type="range"
                                id="distanceFromLocation"
                                name="distanceFromLocation"
                                min={50}
                                max={9999}
                                value={formData.distanceFromLocation || 500}
                                onChange={handleInputChange}
                                className="w-full focus:outline-none  focus:ring-blue-500 transition-colors duration-300 cursor-pointer"
                                style={{
                                    background: "linear-gradient(to right, #2b6cb0, #4299e1)",
                                    color: "black",
                                }}
                            />
                            <span className="text-gray-300">
                {formData.distanceFromLocation || 500} KM
              </span>
                        </div>

                        <div>
                            <label
                                htmlFor="travelStyle"
                                className="text-lg mb-1 block text-gray-200"
                            >
                                Gaya Perjalanan?
                            </label>
                            <select
                                id="travelStyle"
                                name="travelStyle"
                                value={formData.travelStyle}
                                onChange={handleInputChange}
                                className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
                            >
                                <option value="">Select an option</option>
                                <option value="Road trip">Road trip</option>
                                <option value="Cultural">Cultural</option>
                                <option value="SightSeeing">SightSeeing</option>
                                <option value="Museums">Museums</option>
                                <option value="Adventure">Adventure</option>
                                <option value="Relaxation">Relaxation</option>
                                <option value="Wildlife exploration">
                                    Wildlife exploration
                                </option>
                                <option value="Camping/Hiking">Camping/Hiking</option>
                                <option value="Theme parks">Theme parks</option>
                                <option value="Nightlife">Nightlife</option>
                                <option value="Outdoor">Outdoor</option>
                            </select>
                        </div>

                        <div className="col-span-2 text-center">
                            <IconButton onClick={() => setShowMore(!showMore)}>
                                <AddCircleOutlineIcon
                                    className="text-white animate-pulse"
                                    style={{ fontSize: 40 }}
                                />
                            </IconButton>
                        </div>

                        {/* Remaining inputs, hidden initially */}
                        {showMore && (
                            <>
                                <div>
                                    <label
                                        htmlFor="tripDuration"
                                        className="text-lg mb-1 block text-gray-200"
                                    >
                                        Durasi Perjalanan (hari)?
                                    </label>
                                    <input
                                        type="range"
                                        id="tripDuration"
                                        name="tripDuration"
                                        min={1}
                                        max={4}
                                        value={formData.tripDuration}
                                        onChange={handleInputChange}
                                        className="w-full focus:outline-none  focus:ring-blue-500 transition-colors duration-300 cursor-pointer"
                                        style={{
                                            background: "linear-gradient(to right, #2b6cb0, #4299e1)",
                                            color: "black",
                                        }}
                                    />
                                    <span className="text-gray-300">{formData.tripDuration}</span>
                                </div>
                                <div>
                                    <label
                                        htmlFor="budget"
                                        className="text-lg mb-1 block text-gray-200"
                                    >
                                        Anggaran
                                    </label>
                                    <input
                                        type="text"
                                        id="budget"
                                        name="budget"
                                        placeholder="in IDR (excl. tiket pesawat)"
                                        value={formData.budget}
                                        onChange={handleInputChange}
                                        className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300"
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="travellingAs"
                                        className="text-lg mb-1 block text-gray-200"
                                    >
                                        Dalam kapasitas apa Anda?
                                    </label>
                                    <select
                                        id="travellingAs"
                                        name="travellingAs"
                                        value={formData.travellingAs}
                                        onChange={handleInputChange}
                                        className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
                                    >
                                        <option value="solo traveler">Solo Traveler</option>
                                        <option value="Family">Family</option>
                                        <option value="Couple">Couple</option>
                                        <option value="Friends">Friends</option>
                                    </select>
                                </div>

                                <div>
                                    <label
                                        htmlFor="specialOccasion"
                                        className="text-lg mb-1 block text-gray-200"
                                    >
                                        Acara khusus?
                                    </label>
                                    <select
                                        id="specialOccasion"
                                        name="specialOccasion"
                                        value={formData.specialOccasion}
                                        onChange={handleInputChange}
                                        className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
                                    >
                                        <option value="">Select an occasion</option>
                                        <option value="Honeymoon">Honeymoon</option>
                                        <option value="Anniversary">Anniversary</option>
                                        <option value="Birthday party">Birthday party</option>
                                        <option value="Graduation celebration">
                                            Graduation celebration
                                        </option>
                                        <option value="Bachelor party">Bachelor party</option>
                                        <option value="Bachelorette party">
                                            Bachelorette party
                                        </option>
                                        <option value="Family reunion">Family reunion</option>
                                        <option value="Retirement getaway">
                                            Retirement getaway
                                        </option>
                                    </select>
                                </div>

                                <div>
                                    <label
                                        htmlFor="terrain"
                                        className="text-lg mb-1 block text-gray-200"
                                    >
                                        Jenis medan
                                    </label>
                                    <select
                                        id="terrain"
                                        name="terrain"
                                        value={formData.terrain}
                                        onChange={handleInputChange}
                                        className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
                                    >
                                        <option value="">Select terrain</option>
                                        <option value="Mountain">Mountain</option>
                                        <option value="Beach">Beach</option>
                                        <option value="Plain">Plain</option>
                                        <option value="Forest">Forest</option>
                                    </select>
                                </div>
                                <div>
                                    <label
                                        htmlFor="weatherConditions"
                                        className="text-lg mb-1 block text-gray-200"
                                    >
                                        Kondisi cuaca?
                                    </label>
                                    <select
                                        id="weatherConditions"
                                        name="weatherConditions"
                                        value={formData.weatherConditions}
                                        onChange={handleInputChange}
                                        className="w-full rounded-lg p-2 bg-white border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors duration-300 cursor-pointer"
                                    >
                                        <option value="">Select weather</option>
                                        <option value="Sunny">Sunny</option>
                                        <option value="Cold">Cold</option>
                                        <option value="Rain">Rain</option>
                                        <option value="Snowfall">Snowfall</option>
                                    </select>
                                </div>
                            </>
                        )}
                    </div>
                    <button
                        type="submit"
                        className="bg-purple-600 text-white rounded-lg py-2 px-4 w-full sm:w-auto hover:bg-blue-600 transition-all duration-300 cursor-pointer"
                    >
                        Temukan Tujuan Saya
                    </button>
                </form>
            </div>
            {showPopup && popup}
        </div>
    );
};

export default SuggestionsForm;
