import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DropDownMenu from "../components/DropDownMenu";
import { Button } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  FaEllipsisV,
  FaTimes,
  FaSignInAlt,
  FaUserPlus,
  FaLightbulb,
  FaUser,
  FaSignOutAlt,
} from "react-icons/fa";
import { UserAuth } from "../context/AuthContext";
import FeedbackForm from "../components/FeebackForm";

// Background videos from cloudinary
const vidArrayVertical = [
      "https://res.cloudinary.com/ddw6tgza0/video/upload/v1690008733/homepage%20videos/vertical/production_id_4599004_540p_e8xnfp.mp4",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/v1690008733/homepage%20videos/vertical/video_540p_vbbqmr.mp4",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/v1690008733/homepage%20videos/vertical/production_id_4434150_540p_jn07fd.mp4",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/v1690008733/homepage%20videos/vertical/production_id_4920770_540p_hjfnln.mp4",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/v1690008733/homepage%20videos/vertical/production_id_4444485_540p_cidx0s.mp4",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/v1690008733/homepage%20videos/vertical/pexels-daniel-ponomarev-9669392_540p_zx4axx.mp4",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/v1690008734/homepage%20videos/vertical/pexels-tima-miroshnichenko-6010489_540p_o66lsa.mp4",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/v1690008734/homepage%20videos/vertical/production_id_4434136_720p_kaszrm.mp4",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/v1690008734/homepage%20videos/vertical/pexels-mehmet-ali-turan-5512609_540p_k4btgi.mp4",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/v1690008734/homepage%20videos/vertical/pexels-c-technical-6117571_540p_qhvolg.mp4",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/f_auto:video,q_auto/v1/homepage%20videos/vertical/production_id_3992122_540p_iyizal",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/f_auto:video,q_auto/v1/homepage%20videos/vertical/tjklqicnmc9en7y44kq8",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/f_auto:video,q_auto/v1/homepage%20videos/vertical/d96tsruuuijx2otzgblj",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/f_auto:video,q_auto/v1/homepage%20videos/vertical/ekubgmwgps3marvgmw79"
];
const vidArrayHorizontal =[
  "https://res.cloudinary.com/djtigzyfc/video/upload/f_auto:video,q_auto/r9sg3mprrbxxkterzr45",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/f_auto:video,q_auto/v1/homepage%20videos/horizontal/ehztiikuqbkdfaoiprgr",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/f_auto:video,q_auto/v1/homepage%20videos/horizontal/fb8qlzzndwtprvses7qt",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/f_auto:video,q_auto/v1/homepage%20videos/horizontal/dchpfmnyq9iqiolyjd7i",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/f_auto:video,q_auto/v1/homepage%20videos/horizontal/jbexlirylnau3gvgbno5",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/f_auto:video,q_auto/v1/homepage%20videos/horizontal/irlt5gxut7azxyzrywms",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/f_auto:video,q_auto/v1/homepage%20videos/horizontal/m0xiqvw0dqv9pwdjgshi",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/f_auto:video,q_auto/v1/homepage%20videos/horizontal/hbaq2oaq507fluvsgevc",
    "https://res.cloudinary.com/ddw6tgza0/video/upload/f_auto:video,q_auto/v1/homepage%20videos/horizontal/aelqrntgf100afrajho0"
]

let isMobile = window.innerWidth / window.innerHeight > 1 ? false : true;
let randomSrc;



if(isMobile) {
  const randomIndex = Math.floor(Math.random() * vidArrayVertical.length);
  randomSrc = vidArrayVertical[randomIndex];
} else {
  const randomIndex = Math.floor(Math.random() * vidArrayHorizontal.length);
  randomSrc = vidArrayHorizontal[randomIndex];
}

export default function RoamReady() {
  const [placeName, setPlaceName] = useState("");
  const [isValidPlace, setIsValidPlace] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Added state for menu
  const { user, logout } = UserAuth();
  const [options, setOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const formRef = useRef(null);
  const [isFetching, setIsFetching] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false); // State to track expansion

  const [isBannerExpanded, setIsBannerExpanded] = useState(false); // State to track banner expansion

  const handleBannerClick = () => {
    setIsBannerExpanded(!isBannerExpanded); // Toggle banner expansion state
  };
   const handleBookNowClick = () => {
    // Redirect to the tour information page
    window.open("/labuan-bajo-tour", "_blank");
  };

    const handleBookNowClickVietnam = () => {
        // Redirect to the tour information page
        window.open("/vietnam-tour", "_blank");
    };

  const redirectToTestPage = () => {
    navigate("/custom");
  };

  // Style for the Collapsible Content
  const contentStyle = {
    height: isExpanded ? "auto" : "0px",
    overflow: "hidden",
    transition: "height 0.3s ease-in-out",
  };

  const ChevronDown = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 9l-7 7-7-7"
      />
    </svg>
  );

  const handleInputChange = (e, value, reason) => {
    // Update placeName state
    if (reason === "input") {
      setPlaceName(value);
      if (value.length >= 3) {
        // Check if value length is greater than or equal to 3
        setIsDropdownOpen(true); // Open the dropdown
        // Fetch autocomplete options based on the input value
        fetchOptions(value);
      } else {
        setIsDropdownOpen(false); // Close the dropdown
        setOptions([]);
      }
    }
  };

  const fetchOptions = async (inputValue) => {
    try {
      setIsFetching(true); // Set fetching state to true
      const response = await fetch(
        `${process.env.REACT_APP_api_url}api/autocomplete?input=${inputValue}`
      );
      if (!response.ok) throw Error(response.statusText);
      const data = await response.json();
      setOptions(data);
    } catch (error) {
      console.error("Autocomplete fetch failed", error);
      setOptions([]);
    } finally {
      setIsFetching(false); // Set fetching state to false regardless of success or failure
    }
  };

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setPlaceName(event.target.value);
    }
  };

  const handleOutsideClick = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      event.preventDefault();
      setPlaceName((prevPlaceName) => prevPlaceName.trim()); // Update the placeName with the trimmed value
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  function toggleMenu() {
    setIsMenuOpen((prevState) => !prevState);
  }

  async function onSubmit(event) {
    event.preventDefault();

    if (placeName.trim() === "") {
      setIsValidPlace(false);
      return;
    }

    setIsValidPlace(true);
    setIsLoading(true); // Start loading

    const uniqueId = uuidv4();

    navigate(`/itinerary/${placeName}/${uniqueId}`);
    setIsLoading(false); // Stop loading
  }

  function validatePlaceName(placeName) {
    // This function now always return true, regardless of the place name.
    return true;
  }

  // Prevent scrolling on homepage
  // useEffect(() => {
  //   document.body.style.overflow = "hidden"; // Prevent scrolling on the body element
  //
  //   return () => {
  //     document.body.style.overflow = "auto"; // Restore scrolling when component is unmounted
  //   };
  // }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("Firebase: Signed Out");
    } catch (err) {
      console.error(err);
    }
  };

  // Feedback form
  const handleFeedbackSent = () => {
    alert("Feedback received. Thank you!");
  };

  return (
    <div
      className={`min-h-screen flex pt-40 justify-center ${
        isMenuOpen ? "bg-gray-500" : "bg-black"
      }`}
      style={{ overflow: "hidden" }}
    >
      <video
        className="fixed top-0 left-0 object-cover w-full h-full z-0"
        src={randomSrc}
        autoPlay
        loop
        muted
        playsInline
      />

      {/* Three-dot menu icon */}
      <div className="fixed top-4 right-4 z-40">
        <button className="text-white text-4xl" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaEllipsisV />}
        </button>
      </div>

      {/* Menu */}
      {isMenuOpen && (
        <div className="fixed top-0 right-0 bottom-0 z-30 bg-black bg-opacity-95 transition duration-300 transform translate-x-0 w-full sm:max-w-xs ">
          <div className="h-full flex flex-col justify-center mt-[-15rem] items-start pl-10">
            {/* Check if user is logged in */}
            {user && user.email ? (
              <>
                {/* Display Account button */}
                <button
                  onClick={() => navigate("/account")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaUser className="mr-2" /> Account
                </button>

                {/* Display Logout button */}
                <button
                  onClick={handleLogout}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaSignOutAlt className="mr-2" /> Logout
                </button>
              </>
            ) : (
              <>
                {/* Display Login button */}
                <button
                  onClick={() => navigate("/login")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaSignInAlt className="mr-2" /> Login
                </button>

                {/* Display Signup button */}
                <button
                  onClick={() => navigate("/signup")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaUserPlus className="mr-2" /> Signup
                </button>
              </>
            )}

            {/* About Us */}
            <button
              onClick={() => navigate("/about-us")}
              className="text-white text-xl flex items-center py-2 px-4 w-full text-left"
            >
              <FaLightbulb className="mr-2" /> About Us
            </button>
            <div className="fixed bottom-6 left-4 bg-gray-600 text-white rounded-full p-3 shadow-lg transition duration-300 ease-in-out hover:bg-blue-600 focus:outline-none">
              <FeedbackForm onFeedbackSent={handleFeedbackSent} />
            </div>
          </div>
        </div>
      )}

      <main className="px-4 z-10">
        {!isLoading && (
            <div className=" mx-auto">
              <img
                  src="/new.png"
                  alt="Logo"
                  className="mx-auto mt-[-8rem] mb-[-4rem]"
              />
              <h6 className="text-lg  text-center mb-8 text-white hint-of-purple-text">
                Your AI travel companion
              </h6>
              <style jsx>{`
                .hint-of-purple-text {
                  text-shadow: 1px 1px 2px rgba(123, 58, 241, 0.5);
                }
              `}</style>
            </div>
        )}

        {isLoading ? (
            <div className="flex justify-center items-center h-screen">
              <div className="flex flex-col items-center">
                <p className="text-white text-5xl font-bold mb-10">
                  Launching into Exploration
                </p>
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            </div>
        ) : (
            <form ref={formRef} onSubmit={onSubmit} className="max-w-md mx-auto ">
              <div className="relative flex items-center ">
                <Autocomplete
                    id="combo-box-demo"
                    options={options}
                    getOptionLabel={(option) => option.description}
                    noOptionsText={
                      isFetching
                          ? "Loading..."
                          : "Not found, Are you sure this place exists?"
                    }
                    style={{width: "100%"}}
                    onChange={(e, value) => {
                      if (value) {
                        setPlaceName(value.description);
                        setIsDropdownOpen(false); // Close the dropdown when an option is selected
                      }
                    }}
                    onInputChange={handleInputChange}
                    open={isDropdownOpen}
                    disableListWrap
                    clearOnBlur={false} // Set clearOnBlur to false
                    onBlur={() => setIsDropdownOpen(false)}
                    popupIcon={null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            type="text"
                            placeholder="Masukkan Destinasi Anda"
                            className={`w-full rounded-full border-2 focus:outline-none font-bold focus:border-blue-500 text-gray-800 bg-gray-200 ${
                                !isValidPlace && "border-red-500"
                            }`}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              style: {
                                fontSize: "16px",
                                paddingLeft: "2em",
                                paddingRight: "5em",
                                width: "auto",
                                height: "4em",
                              },
                            }}
                            onBlur={handleBlur} // Add onBlur event handler
                        />
                    )}
                />

                <button
                    type="submit"
                    className="absolute inset-y-0 right-0 top-1/2 transform -translate-y-1/2 text-white font-bold bg-[#7b3af1] rounded-full px-4 py-1 mr-6 transition duration-300 ease-in-out hover:scale-105 focus:outline-none shadow-lg"
                >
                  <span className="animate-pulse">Jelajahi</span>
                </button>
              </div>

              {!isValidPlace && (
                  <p className="text-red-500 text-center mt-4 font-bold ">
                <span role="img" aria-label="Error">
                  ⚠️
                </span>{" "}
                    Oops! Please enter a valid destination.
                  </p>
              )}
            </form>
        )}

        {/* Divider */}
        <hr className="my-5 border-t-2 border-purple-300 opacity-60"/>

        {/* Distinctive Styling for Dropdown */}
        <div
            className="bg-white bg-opacity-50 backdrop-blur-md p-4 rounded-3xl shadow-md relative transition duration-300 hover:bg-opacity-70 mt-4 cursor-pointer"
            onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className="flex justify-between items-center">
            <p className="text-purple-800 font-semibold mb-2">
              Bingung mau liburan kemana?
            </p>

            {/* Chevron icon indicating expansion/collapse */}
            <span
                className={`transform transition-transform duration-300 ${
                    isExpanded ? "rotate-180" : ""
                }`}
            >
              <ChevronDown/>
            </span>
          </div>

          <div style={contentStyle}>
            <p className="text-gray-800 text-sm mb-3">
              Based on your preferences, get your personalized destination!{" "}
              <br/>A premium feature for our early users.
            </p>
            <Button
                variant="contained"
                onClick={redirectToTestPage}
                startIcon={<LocationOnIcon/>}
                sx={{
                  backgroundColor: "#7b3af1",
                  color: "#ffffff",
                  fontWeight: "bold",
                  fontFamily: "Arial",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  textTransform: "none",
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  "&:hover": {
                    backgroundColor: "#7b3af1",
                    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.16)",
                  },
                  "&:active": {
                    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.16)",
                    transform: "scale(0.95)",
                  },
                }}
            >
              Find your Destination
            </Button>
          </div>
        </div>
        {/* Banner Labuan Bajo*/}
        <div
            className="bg-white bg-opacity-50 backdrop-blur-md p-4 rounded-3xl shadow-md relative transition duration-300 hover:bg-opacity-70 mt-4 cursor-pointer">
          <div className="flex justify-between items-center" onClick={handleBannerClick}>
            <p className="text-blue-800 font-semibold  mb-2">
              <span className="animate-pulse">
Perjalanan ke Labuan Bajo?</span>
            </p>
            <span className={`transform transition-transform duration-300 ${isBannerExpanded ? "rotate-180" : ""}`}>
              <ChevronDown/>
            </span>
          </div>
          {isBannerExpanded && (
              <div>
                <p className="text-gray-800 text-sm mb-3">
                  Pesan dengan mitra perjalanan terpercaya kami!
                </p>
                <button
                    className="bg-[#7b3af1] text-white py-2 px-4 rounded-full font-semibold hover:bg-blue-400 transition duration-300"
                    onClick={handleBookNowClick}
                >
                  Book Now
                </button>
              </div>
          )}
        </div>
        {/*  banner Vietnam*/}
        <div
            className="bg-white bg-opacity-50 backdrop-blur-md p-4 mb-6 rounded-3xl shadow-md relative transition duration-300 hover:bg-opacity-70 mt-4 cursor-pointer">
          <div className="flex justify-between items-center" onClick={handleBannerClick}>
            <p className="text-blue-800 font-semibold  mb-2">
              <span className="animate-pulse">
Berencana pergi ke Vietnam?</span>
            </p>
            <span className={`transform transition-transform duration-300 ${isBannerExpanded ? "rotate-180" : ""}`}>
              <ChevronDown/>
            </span>
          </div>
          {isBannerExpanded && (
              <div>
                <p className="text-gray-800 text-sm mb-3">
                    Pesan dengan mitra perjalanan tepercaya kami JungleBoss
                </p>
                <button
                    className="bg-[#7b3af1] text-white py-2 px-4 rounded-full font-semibold hover:bg-blue-400 transition duration-300"
                    onClick={handleBookNowClickVietnam}
                >
                  Book Now
                </button>
              </div>
          )}
        </div>
      </main>
    </div>
  );
}
