import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import Modal from "react-modal";

import { UserAuth } from "../context/AuthContext";

Modal.setAppElement("#root");

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [verifyModalIsOpen, setVerifyModalIsOpen] = useState(false);

  const navigate = useNavigate();
  const { signIn, signInWithGoogle, isVerified } = UserAuth();

  // Login using Form
  const handleSignIn = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(""); // Reset any previous error messages

    try {
      await signIn(email, password);
      navigate("/");
    } catch (err) {
      if (
          err.code === "auth/user-not-found" ||
          err.code === "auth/wrong-password"
      ) {
        setErrorMessage("Username or password is incorrect.");
      } else if (
          err.message === "Please verify your email before logging in."
      ) {
        setVerifyModalIsOpen(true);
      } else {
        setErrorMessage(err.message); // For other unexpected errors
      }
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  // SignIn with google function
  const signInGoogle = async () => {
    try {
      await signInWithGoogle();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
      <div className="min-h-screen flex flex-col bg-gray-900">
        <div className="fixed top-4 right-4 bg-gray-800 text-white rounded-full p-3 shadow-lg transition duration-300 ease-in-out hover:bg-gray-700 focus:outline-none">
          <Link
              to="/"
              className="text-white hover:text-gray-300 transition duration-200"
          >
            <FaHome className="h-6 w-6" />
          </Link>
        </div>
        <div className="flex items-center justify-center flex-grow">
          <div className="w-full max-w-md p-4">
            <form
                onSubmit={handleSignIn}
                className="bg-gray-800 text-white shadow-lg rounded-xl px-8 pt-6 pb-8"
            >
              <div className="mb-6">
                <label
                    className="block text-white text-lg font-bold mb-2"
                    htmlFor="email"
                >
                  Email
                </label>
                <input
                    className="shadow appearance-none border rounded-lg w-full py-3 px-3 text-white leading-tight focus:outline-none focus:shadow-outline bg-gray-700 transition duration-500 ease-in-out transform focus:scale-105"
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                />
              </div>
              <div className="mb-6">
                <label
                    className="block text-white text-lg font-bold mb-2"
                    htmlFor="password"
                >
                  Password
                </label>
                <input
                    className="shadow appearance-none border rounded-lg w-full py-3 px-3 text-white mb-3 leading-tight focus:outline-none focus:shadow-outline bg-gray-700 transition duration-500 ease-in-out transform focus:scale-105"
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    minLength={6}
                    required
                />
              </div>
              {errorMessage && (
                  <div className="mb-4 p-2 bg-red-600 text-white rounded">
                    {errorMessage}
                  </div>
              )}
              <div className="flex items-center flex-col sm:flex-row justify-center">
                <button
                    className={`bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-4 rounded-lg focus:outline-none focus:shadow-outline transform transition duration-500 ease-in-out ${
                        isSubmitting
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:opacity-80"
                    }`}
                    type="submit"
                    disabled={isSubmitting}
                >
                  {isSubmitting ? (
                      <svg
                          className="animate-spin h-5 w-5 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                      >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm9-1.647V21c3.042 0 5.824-1.135 7.938-3l-2.647-3A7.962 7.962 0 0112 20h0z"
                        ></path>
                      </svg>
                  ) : null}
                  {isSubmitting ? "Signing In..." : "Login"}
                </button>
                <div className="my-4 sm:my-0 mx-2">
                  <div className="h-6 bg-white w-1"></div>
                </div>
                <button
                    className="bg-white hover:bg-black hover:text-white text-gray-800 font-bold py-2 px-4 rounded-lg flex items-center focus:outline-none focus:shadow-outline transform transition duration-200 ease-in-out hover:opacity-80"
                    onClick={signInGoogle}
                >
                  <img
                      src="google.png"
                      alt="Google Icon"
                      className="h-6 w-6 mr-2"
                  />
                  Sign In with Google
                </button>
              </div>
            </form>
            <p className="text-center text-white pt-4">
              Don't have an account?
              <button
                  className="text-blue-500 hover:text-blue-400 transition duration-200 px-2"
                  onClick={() => navigate("/signup")}
              >
                Register here
              </button>
            </p>
          </div>
        </div>
        {/* Email Verification Modal */}
        <Modal
            isOpen={verifyModalIsOpen}
            onRequestClose={() => setVerifyModalIsOpen(false)}
            contentLabel="Email Verification Modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
              content: {
                width: "80%",
                maxWidth: "400px",
                padding: "20px",
                backgroundColor: "#2d2d2d",
                color: "white",
                borderRadius: "10px",
                border: "none",
                position: "relative",
                bottom: "auto",
                left: "auto",
                right: "auto",
                transform: "none",
                margin: "0",
              },
            }}
        >
          <h2 className="text-2xl font-bold mb-4">Verify Your Email</h2>
          <p className="mb-4">
            You need to verify your email before logging in. Please check your
            inbox.
          </p>
          <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={() => {
                setVerifyModalIsOpen(false);
              }}
          >
            Understood
          </button>
        </Modal>
      </div>
  );
};

export default Login;
