import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/streamingItinerary.css";
import {
  FaEllipsisV,
  FaTimes,
  FaSignInAlt,
  FaUserPlus,
  FaLightbulb,
  FaUser,
  FaSignOutAlt,
  FaInstagram,
  FaMapMarkedAlt,
  FaShareAlt,
  FaSave,
  FaShareSquare,
  FaEllipsisH,
  FaPaperPlane,
  FaHome,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import FeedbackForm from "../components/FeebackForm";

const PEXELS_API_KEY = process.env.REACT_APP_PEXELS_API_KEY;

export default function StreamingItinerary({ city }) {
  const [result, setResult] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [currentImage, setCurrentImage] = useState(
    "https://res.cloudinary.com/djtigzyfc/image/upload/f_auto,q_auto/ltflp1ffqpfpvmbye6sl"
  ); // Set initial image to placeholder

  const resultRef = useRef();

  // Counter for page refresh
  const [count, setCount] = useState(70);
  const [timerId, setTimerId] = useState(null);

  useEffect(() => {
    setTimerId(
      setInterval(() => {
        setCount((count) => count - 1);
      }, 1000)
    );

    return () => {
      clearInterval(timerId);
    };
  }, []);

  useEffect(() => {
    if (count === 0) {
      clearInterval(timerId);
      window.location.reload();
    }
  }, [count, timerId]); // Add timerId to the dependency array

  // Fetching images from Pexels
  useEffect(() => {
    async function fetchImage() {
      try {
        const response = await fetch(
          `https://api.pexels.com/v1/search?query=${city}&per_page=1`,
          {
            headers: {
              Authorization: PEXELS_API_KEY,
            },
          }
        );

        const data = await response.json();
        const photoUrl = data.photos[0]?.src.landscape;

        if (photoUrl) {
          setCurrentImage(photoUrl);
        }
      } catch (error) {
        console.error("Error fetching image from Pexels:", error);
      }
    }

    if (city) {
      fetchImage();
    }
  }, [city]);

  useEffect(() => {
    resultRef.current = result;
  }, [result]);

  useEffect(() => {
    async function streamData() {
      if (city !== "") {
        setResult("");

        const serverUrl = `${process.env.REACT_APP_api_url}api/chat`;

        fetch(serverUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: city,
          }),
        })
          .then((response) => {
            const reader = response.body.getReader();
            let resultText = "";
            let readChunk = ({ done, value }) => {
              let chunk = new TextDecoder().decode(value);
              let lines = chunk.split("\n");
              for (let line of lines) {
                if (line.startsWith("data: ")) {
                  line = line.slice("data: ".length);
                  if (line === "[DONE]") {
                    continue;
                  }
                  let data;
                  try {
                    data = JSON.parse(line);
                  } catch (error) {
                    continue;
                  }
                  if (
                    data.choices &&
                    data.choices[0] &&
                    data.choices[0].delta &&
                    data.choices[0].delta.content
                  ) {
                    resultText += data.choices[0].delta.content;
                    setResult(resultText); // Update the result in real-time
                  }
                }
              }
              if (done) {
                return;
              }
              reader.read().then(readChunk);
            };
            reader.read().then(readChunk);
          })
          .catch((error) => {
            console.error("Error:", error);
            setResult("An error occurred. Please try again later.");
          });
      } else {
        alert("please insert a prompt");
      }
    }
    streamData();
  }, []);

  // HomePage header design

  const { user, logout } = UserAuth();
  const navigate = useNavigate();
  // Feedback form
  const handleFeedbackSent = () => {
    alert("Feedback received. Thank you!");
  };

  // Three dot menu
  function toggleMenu() {
    setIsMenuOpen((prevState) => !prevState);
  }
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("Firebase: Signed Out");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen px-2 md:px-0">
      <div className="relative w-full h-[300px] overflow-hidden rounded-xl shadow-lg mb-4">
        <img
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={currentImage}
          alt="City"
          onError={(e) => {
            e.target.onerror = null; // To avoid infinite looping in case placeholder image also fails
            setCurrentImage(
              "https://res.cloudinary.com/djtigzyfc/image/upload/f_auto,q_auto/ltflp1ffqpfpvmbye6sl"
            );
          }}
        />
      </div>

      {/* Countdown and Home button */}
      <div className="w-full flex flex-col md:flex-row justify-between items-center mb-4 px-2">
        <Link
          to="/"
          className="px-4 py-2 text-white bg-purple-600 rounded-md hover:bg-black hover:text-white transition duration-200 mb-2 md:mb-0"
        >
          Back to Home
        </Link>
        <div className="bg-purple-600 text-white rounded px-2 py-1 animate-blink">
          <span>Itinerary dasar sedang dimuat. Versi ditingkatkan dalam </span>
          <span className="font-bold">{count}</span>
          <span> detik.</span>
        </div>
      </div>

      {/* Three-dot menu icon */}
      <div className="fixed top-4 right-4 z-40">
        <button className="text-white text-4xl" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaEllipsisV />}
        </button>
      </div>

      {/* Menu */}
      {isMenuOpen && (
        <div className="fixed top-0 right-0 bottom-0 z-30 bg-black bg-opacity-95 transition duration-300 transform translate-x-0 w-full sm:max-w-xs ">
          <div className="h-full flex flex-col justify-center mt-[-15rem] items-start pl-10">
            {/* Check if user is logged in */}
            {user && user.email ? (
              <>
                {/* Display Account button */}
                <button
                  onClick={() => navigate("/account")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaUser className="mr-2" /> Account
                </button>

                {/* Display Logout button */}
                <button
                  onClick={handleLogout}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaSignOutAlt className="mr-2" /> Logout
                </button>
              </>
            ) : (
              <>
                {/* Display Login button */}
                <button
                  onClick={() => navigate("/login")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaSignInAlt className="mr-2" /> Login
                </button>

                {/* Display Signup button */}
                <button
                  onClick={() => navigate("/signup")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaUserPlus className="mr-2" /> Signup
                </button>
              </>
            )}

            {/* Suggestion button */}
            <button
              onClick={() => navigate("/about-us")}
              className="text-white text-xl flex items-center py-2 px-4 w-full text-left"
            >
              <FaLightbulb className="mr-2" /> About Us
            </button>
            <div className="fixed bottom-6 left-4 bg-gray-600 text-white rounded-full p-3 shadow-lg transition duration-300 ease-in-out hover:bg-green-600 focus:outline-none">
              <FeedbackForm onFeedbackSent={handleFeedbackSent} />
            </div>
          </div>
        </div>
      )}

      {result && (
        <div className="w-full px-2 text-black font-bold text-xl font-sans streaming">
          <h4>Bagus! Berikut adalah rincian Itinerary untuk perjalanan Anda ke {city}</h4>
          <div dangerouslySetInnerHTML={{ __html: result }} />
        </div>
      )}
    </div>
  );
}
