import { useState, useContext } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const FeedbackForm = ({ onFeedbackSent }) => {
  const { user } = UserAuth();
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [feedbackType, setFeedbackType] = useState("Feature Request");
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const navigate = useNavigate();

  const handleSendFeedback = async () => {
    if (!user) {
      console.log("User is not authenticated");
      return;
    }

    const feedbackData = {
      feedbackType,
      feedbackMessage,
      timestamp: new Date().toISOString(),
      userEmail: user.email,
      userId: user.uid,
    };

    const feedbacksRef = collection(db, "feedbacks");
    await addDoc(feedbacksRef, feedbackData);

    console.log("Feedback sent");
    onFeedbackSent();
    setShowFeedbackForm(false);
    setFeedbackMessage("");
  };

  const handleClick = () => {
    if (!user) {
      alert("Please login to submit feedback");
      navigate("/login");
      return;
    }
    setShowFeedbackForm(true);
  };

  return (
    <>
      <button
        onClick={handleClick}
        className="text-white hover:text-gray-300 transition duration-200"
      >
        Feedback
      </button>
      {showFeedbackForm && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    Send Feedback
                  </h3>
                  <div>
                    <div className="mt-4">
                      <button
                        className={`py-2 px-4 m-2 ${
                          feedbackType === "Feature Request"
                            ? "bg-blue-500"
                            : "bg-gray-200"
                        } text-white rounded`}
                        onClick={() => setFeedbackType("Feature Request")}
                      >
                        Feature Request
                      </button>
                      <button
                        className={`py-2 px-4 m-2 ${
                          feedbackType === "Bug" ? "bg-red-500" : "bg-gray-200"
                        } text-white rounded`}
                        onClick={() => setFeedbackType("Bug")}
                      >
                        Bug
                      </button>
                    </div>
                    <div className="mt-4">
                      <textarea
                        value={feedbackMessage}
                        onChange={(e) => setFeedbackMessage(e.target.value)}
                        className="w-full h-32 p-2 border border-gray-300 rounded text-black"
                        placeholder="Write your feedback here..."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleSendFeedback}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm leading-5"
                >
                  Send Feedback
                </button>
                <button
                  onClick={() => setShowFeedbackForm(false)}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm leading-5"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackForm;
