import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { db } from "../config/firebase";
import {
  getDocs,
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
// import DirectDropDownMenu from "../components/DirectDropDownMenu";

export default function Account() {
  const { user, logout } = UserAuth();
  const navigate = useNavigate();
  const [itineraries, setItineraries] = useState([]);
  const [images, setImages] = useState({});
  const [loading, setLoading] = useState(false);
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const itinearyRef = collection(db, "plans");

  function formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("Firebase: Signed Out");
    } catch (err) {
      console.error(err);
    }
  };

  async function getImages(placeName) {
    try {
      const apiKey = process.env.REACT_APP_PIXABAY_API_KEY;
      const response = await fetch(
        `https://pixabay.com/api/?key=${apiKey}&q=${encodeURIComponent(
          placeName
        )}&image_type=photo`
      );
      const data = await response.json();
      if (data.hits && data.hits.length > 0) {
        setImages((prevImages) => ({
          ...prevImages,
          [placeName]: data.hits[0].largeImageURL,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getItineraries() {
    setLoading(true);
    try {
      const q = query(itinearyRef, where("userId", "==", user?.uid));
      const querySnapshot = await getDocs(q);
      const filteredData = querySnapshot.docs.map((doc) => {
        let data = doc.data();
        if (data.date) {
          data.date = data.date.toDate();
        }
        getImages(data.placeName);
        return {
          ...data,
          id: doc.id,
        };
      });

      setItineraries(filteredData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user && user.uid) {
      getItineraries();
    }
  }, [user]);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };
  const item = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  };

  const handleFeedbackSent = () => {
    alert("Feedback received. Thank you!");
  };

  return (
    <div>
      {user?.email ? (
        <div className="min-h-screen bg-gray-900 text-gray-400 p-6">
          <div className="fixed top-4 right-4 bg-gray-800 text-white rounded-full p-3 shadow-lg transition duration-300 ease-in-out hover:bg-gray-700 focus:outline-none">
            <Link
              to="/"
              className="text-white hover:text-gray-300 transition duration-200"
            >
              <FaHome className="h-6 w-6" />
            </Link>
          </div>
          <div className="flex justify-center mb-8 mt-[-3rem]">
            <img className="" src="/new.png" alt="Logo" />
          </div>
          <header className="flex flex-col md:flex-row justify-start items-center mb-8">
            <div className="md:flex-1 flex flex-col-reverse md:flex-row gap-5 mb-[-1rem]">
              <h1 className="text-2xl font-bold mb-2 md:mb-0 md:mt-4 text-gray-400">
                Your Travel Plans
              </h1>
              <div className="flex items-center ml-auto">
                <span className="text-lg font-bold mr-4">
                  {user && user.email}
                </span>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="px-4 py-2 bg-red-600 hover:bg-red-700 transition-colors duration-300 rounded text-white shadow cursor-pointer"
                  onClick={handleLogout}
                >
                  Logout
                </motion.button>
              </div>
            </div>
          </header>
          <motion.div
            className="mt-2 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3"
            variants={container}
            initial="hidden"
            animate="show"
          >
            {loading ? (
              // Display skeleton cards while loading
              <>
                <div className=" p-6  text-white shadow-lg cursor-pointer relative">
                  <div className="animate-pulse bg-gray-600 h-36"></div>
                </div>
                <div className="p-6  text-white shadow-lg cursor-pointer relative">
                  <div className="animate-pulse bg-gray-600 h-36"></div>
                </div>
                <div className=" p-6  text-white shadow-lg cursor-pointer relative">
                  <div className="animate-pulse bg-gray-600 h-36"></div>
                </div>
              </>
            ) : (
              itineraries.map((itinerary) => (
                <motion.div
                  className="border border-gray-700 rounded p-6 bg-gray-800 text-white shadow-lg cursor-pointer relative"
                  style={{
                    backgroundImage: `url(${images[itinerary.placeName]})`,
                    backgroundSize: "cover",
                  }}
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.1 }}
                  variants={item}
                  key={itinerary.id}
                  onClick={() => window.open(itinerary.url, "_blank")}
                >
                  <div className="absolute inset-0 bg-black bg-opacity-30"></div>
                  <div className="relative">
                    <h2 className="text-2xl mb-2 text-gray-300">
                      {itinerary.placeName}
                    </h2>
                    <p className="text-xl">
                      {formatDate(new Date(itinerary.date))}
                    </p>
                  </div>
                </motion.div>
              ))
            )}
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
              className="flex items-center justify-center border border-green-600 rounded p-6 bg-green-800 shadow-lg cursor-pointer transition-all duration-100 ease-in-out transform text-4xl text-white"
              onClick={() => navigate("/")}
            >
              +
            </motion.button>
          </motion.div>
          {/* {isDrawerOpen && (
            <DirectDropDownMenu
              isOpen={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
            />
          )} */}
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen w-screen text-4xl">
          Please sign in first!
        </div>
      )}
    </div>
  );
}
