import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import ReactModal from 'react-modal';

const BookNowDialog = ({ onClose }) => {
  const [copied, setCopied] = useState(false);

  const copyCouponCode = () => {
    const couponCode = "AsikTravelLabuan10";
    navigator.clipboard.writeText(couponCode);
    setCopied(true);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg max-w-sm">
        <h2 className="text-xl font-bold mb-4 text-center text-black">Exclusive Offer!</h2>
        <p className="text-center mb-4 text-black">
          Use coupon code{" "}
          <strong className="text-purple-800">"AsikTravelLabuan10"</strong> for
          a 10% discount.
        </p>
        <div className="flex mb-4">
          <input
            readOnly
            value="AsikTravelLabuan10"
            className="flex-grow px-2 py-1 border border-gray-300 rounded-l focus:outline-none text-black"
          />
          <button
            onClick={copyCouponCode}
            className="px-4 py-1 bg-purple-800 text-white rounded-r hover:bg-purple-900"
            disabled={copied}
          >
            {copied ? "Copied!" : "Copy Code"}
          </button>
        </div>
        <p className="text-sm text-gray-600 mb-4 text-center">
          Tolong berikan kode pemesanan ini kepada operator tur mitra kami, <strong className="text-purple-800">Alexandra Komodo Tour</strong>
        </p>
        <button
          onClick={() => window.open("https://alexandrakomodotour.com/")}
          className="w-full bg-purple-800 text-white px-6 py-3 rounded-full font-bold hover:bg-purple-900 transition duration-300 ease-in-out"
        >
          Book with our Partner
        </button>
        <button
          onClick={onClose}
          className="w-full mt-4 bg-gray-300 text-gray-800 px-6 py-3 rounded-full font-bold hover:bg-gray-400 transition duration-300 ease-in-out"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const Labuan = () => {
  const labuanBajoImages = [
    "https://res.cloudinary.com/ddw6tgza0/image/upload/v1697199522/labuan-bajo/lbj-images/wzemnypuqpxqtemfnoad.jpg",
    "https://res.cloudinary.com/ddw6tgza0/image/upload/v1697199522/labuan-bajo/lbj-images/ddins4lddngtxhavlo5v.jpg",
    "https://res.cloudinary.com/ddw6tgza0/image/upload/v1697199522/labuan-bajo/lbj-images/j29jsuwpa6175yebsxiw.jpg",
    "https://res.cloudinary.com/ddw6tgza0/image/upload/v1697199522/labuan-bajo/lbj-images/uwaajgj7a31rxneed67d.jpg",
    "https://res.cloudinary.com/ddw6tgza0/image/upload/v1697199521/labuan-bajo/lbj-images/rjjgwgjwmroolj5epc7s.jpg",
    "https://res.cloudinary.com/ddw6tgza0/image/upload/f_auto,q_auto/v1/labuan-bajo/lbj-images/xar0qbniivvlipphoriw"
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showBookNowDialog, setShowBookNowDialog] = useState(false);

   const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === labuanBajoImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const handleBookNowClick = () => {
    setShowBookNowDialog(true);
  };

  const handleCloseBookNowDialog = () => {
    setShowBookNowDialog(false);
  };

  return (
    <div className="min-h-screen bg-black text-white p-8">
      <div className="flex justify-between items-center mb-4">
        <img src="/new.png" alt="AsikTravel Logo" className="w-12" />
        <button
         onClick={()=>navigate("/")}
          className="bg-white text-purple-800 px-4 py-2 rounded-full font-bold hover:bg-purple-900 transition duration-300 ease-in-out"
        >
          Back
        </button>
      </div>

      <div className="mb-8 mt-8 text-center">
        <h1 className="text-4xl font-bold mb-4">Labuan Bajo Boat Tour</h1>
        <p className="text-lg mb-6">
          Jelajahi keindahan Labuan Bajo dalam tur perahu yang menakjubkan!
        </p>
      </div>

      <div className="relative mb-8">
        <div className="flex justify-center items-center h-96 relative rounded-lg overflow-hidden">
          {labuanBajoImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Labuan Bajo ${index}`}
              className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity ${
                index === currentImageIndex ? "opacity-100" : "opacity-0"
              }`}
            />
          ))}
        </div>
      </div>

      <div className="mb-8 text-center">
        <p className="text-lg mb-6">

Rasakan keindahan luar biasa dari Labuan Bajo dalam tur perahu premium kami. Berlayar melalui air yang jernih kristal, jelajahi teluk-teluk tersembunyi, dan saksikan matahari terbenam yang memukau. Pandu-pandu ahli kami akan memastikan Anda memiliki petualangan yang tak terlupakan.
        </p>
      </div>

      <div className="max-w-3xl mx-auto mb-8 text-center">
        <div className="bg-purple-800 bg-opacity-90 p-6 rounded-lg inline-block w-full">
          <p className="text-2xl font-bold mb-2 text-white">Mitra tur kami</p>
          <p className="text-lg mb-4 text-white">
            Sebagai pengguna AsikTravel, Anda akan memiliki akses ke operator wisata yang kami rekomendasikan dan dapat dipercaya.
          </p>
          <button
             onClick={() => window.location.href = 'https://alexandrakomodotour.com/'}
            className="bg-white text-purple-800 px-6 py-3 rounded-full font-bold hover:bg-purple-900 transition duration-300 ease-in-out mr-2"
          >
            Book Now
          </button>
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-purple-800 text-white px-6 py-3 rounded-full font-bold hover:bg-purple-900 transition duration-300 ease-in-out"
          >
            Watch Video
          </button>
        </div>
      </div>

      <div className="mb-8 text-center">
        <h2 className="text-3xl font-bold mb-4">Itinerary</h2>
        <div className="grid grid-cols-3 gap-4">
          <div className="bg-purple-800 bg-opacity-90 p-4 rounded-lg">
            <h3 className="text-xl font-bold mb-2 text-white">Day 1</h3>
            <p className="mb-2 text-white">Morning: Snorkeling at Pink Beach</p>
            <p className="mb-2 text-white">Afternoon: Visit Rinca Island</p>
            <p className="text-white">Evening: Sunset at Kalong Island</p>
          </div>
          <div className="bg-indigo-800 bg-opacity-90 p-4 rounded-lg">
            <h3 className="text-xl font-bold mb-2 text-white">Day 2</h3>
            <p className="mb-2 text-white">Morning: Komodo Island Tour</p>
            <p className="mb-2 text-white">Afternoon: Snorkeling at Manta Point</p>
            <p className="text-white">Evening: Relax at Kanawa Island</p>
          </div>
          <div className="bg-green-800 bg-opacity-90 p-4 rounded-lg">
            <h3 className="text-xl font-bold mb-2 text-white">Day 3</h3>
            <p className="mb-2 text-white">Morning: Visit Padar Island</p>
            <p className="mb-2 text-white">Afternoon: Snorkeling at Taka Makassar</p>
            <p className="text-white">Evening: Back to Labuan Bajo</p>
          </div>
        </div>
      </div>

      <hr className="my-8 border-t-2 border-gray-600" /> {/* Separator */}

      {/* Video Modal */}
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <div className="text-center">
          <video src="https://res.cloudinary.com/ddw6tgza0/video/upload/f_auto:video,q_auto/v1/labuan-bajo/lbj-video/alhomlij6gjjjce8az0s" controls />
          <button onClick={() => setIsModalOpen(false)}>Close</button>
        </div>
      </ReactModal>

      {showBookNowDialog && (
        <BookNowDialog onClose={handleCloseBookNowDialog} />
      )}

      {/* Footer */}
      <div className="flex justify-between items-center flex-wrap text-center">
        <div className="flex  ">
          <p className="text-lg mr-4">Follow Us:</p>
          <a
            href="https://instagram.com/asik_travel?igshid=OGQ5ZDc2ODk2ZA=="
            target="_blank"
            rel="noopener noreferrer"
            className="text-xl text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out mr-4"
          >
            <FaInstagram />
          </a>
          <a
            href="https://wa.me/+6281296838995"
            target="_blank"
            rel="noopener noreferrer"
            className="text-xl text-green-500 hover:text-green-700 transition duration-300 ease-in-out"
          >
            <FaWhatsapp />
          </a>
        </div>
        <p className="text-lg text-center mt-2">
          &copy; 2023 AsikTravel. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Labuan;
