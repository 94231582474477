import React from "react";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className="min-h-screen py-20 bg-gradient-to-b from-purple-800 to-purple-600 text-white">
      <div className="fixed top-4 right-4 bg-gray-800 text-white rounded-full p-3 shadow-lg transition duration-300 ease-in-out hover:bg-gray-700 focus:outline-none">
        <Link
          to="/"
          className="text-white hover:text-gray-300 transition duration-200"
        >
          <FaHome className="h-6 w-6" />
        </Link>
      </div>
      <div className="container mx-auto px-4 sm:px-8">
        <div className="max-w-md mx-auto bg-purple-900 rounded-lg shadow-lg p-8">
          <div className="flex justify-center mb-6">
            <img src="new.png" alt="Logo AsikTravel" className="h-32 sm:h-40" />
          </div>
          <div className="text-center">
            <p className="text-lg font-semibold">
              Selamat datang di AsikTravel, perencana perjalanan berbasis AI
              kami.
            </p>
            <p className="text-base text-purple-300 my-4">
              Misi kami adalah merevolusi cara Anda berkeliling dunia dengan
              membuat perencanaan itinerari menjadi mudah dan mengasyikkan,
              bahkan untuk sudut terpencil dunia.
            </p>
            <p className="text-base text-purple-300 mb-4">
              Dengan kekuatan AI, AsikTravel menyediakan itinerari yang
              dipersonalisasi melampaui apa yang ditawarkan oleh alat
              perencanaan tradisional. Berbeda dengan layanan lainnya,
              AsikTravel menggali lebih dalam, memastikan Anda dapat merasakan
              esensi sejati dunia, bukan hanya tempat-tempat wisata umum.
            </p>
            <p className="text-base text-purple-300">
              Nikmati petualangan berikutnya dengan pikiran yang tenang dan
              manfaatkan setiap momen bersama AsikTravel.
            </p>
            <div className="mt-8">
              <p className="text-base font-bold">
                Ingin menghubungi kami? Jangan ragu untuk mengirim email ke{" "}
                <a
                  href="mailto:contact@AsikTravel.com"
                  className="text-blue-400 hover:text-blue-500"
                >
                  info@AsikTravel.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
